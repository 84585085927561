@import '../../../assets/css/variables';

.dataGridMain {
    .p-datatable {
        .p-datatable-wrapper {
            .p-datatable-table {
                .p-datatable-thead {
                    tr {
                        th {
                            padding: 0.5rem;
                            line-height: 1.2;
                            height: 50px;
                            vertical-align: middle;
                            white-space: nowrap;

                            &.text-center {
                                .p-column-header-content {
                                    justify-content: center;
                                }
                            }

                            &.text-end {
                                .p-column-header-content {
                                    justify-content: end;
                                }
                            }

                            &:first-child {
                                padding-left: 1.25rem;
                            }


                            &.p-sortable-column {

                                &.p-highlight,
                                &:hover {
                                    span {
                                        .p-sortable-column-icon {
                                            opacity: 1;
                                        }
                                    }
                                }

                                span {
                                    .p-sortable-column-icon {
                                        opacity: 0;
                                    }
                                }
                            }

                            &.theme-action-cell {
                                .p-column-header-content {
                                    padding-left: .4rem;

                                    @include below-md() {
                                        padding-left: 0;
                                        justify-content: center;
                                    }
                                }

                                &.hideAddMappingWidthChange {
                                    @include below-md() {
                                        width: 120px !important;
                                        min-width: 120px !important;
                                    }
                                }
                            }

                            &.theme-table-cell-center {
                                .p-column-header-content {
                                    padding-left: 0;
                                    justify-content: center;
                                }
                            }

                            @include below-md() {
                                &.theme-editor-cell-header {
                                    width: 10px !important;
                                    min-width: 10px !important;
                                }

                                &.hideCheckInMobile,
                                &.hideReorderInMobile,
                                &.theme-hide-cell-in-mobile {
                                    display: none;
                                }
                            }

                            .p-column-filter {
                                .p-column-filter-menu-button {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .p-datatable-tbody {
                    >tr {

                        >td {
                            padding: 0.5rem;
                            height: 50px;
                            vertical-align: middle;
                            max-width: 350px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @include below-lg() {
                                max-width: 200px;
                            }

                            &.max-w-150 {
                                max-width: 150px;
                            }

                            &:first-child {
                                padding-left: 1.25rem;

                                &.theme-reorder-button {
                                    background-position: left 1.65rem center;
                                }
                            }

                            .theme-action-btns {
                                .btn {
                                    &:disabled {
                                        --bs-btn-disabled-color: #{$secondary};
                                        --bs-btn-disabled-opacity: .4;
                                    }
                                }

                                @include below-md() {
                                    justify-content: center;
                                }
                            }

                            &.theme-action-btns-center {
                                .theme-action-btns {
                                    justify-content: center;
                                }
                            }

                            &.theme-reorder-button {
                                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='17.999' height='9.999' viewBox='0 0 17.999 9.999'><path d='M-14321,4941v-1.25h18V4941Zm0-4.374v-1.25h18v1.25Zm0-4.374V4931h18v1.25Z' transform='translate(14321.001 -4931.001)'/></svg>");
                                background-repeat: no-repeat;
                                background-position: left 1em center;

                                .p-datatable-reorderablerow-handle {
                                    opacity: 0;
                                    width: 2rem;
                                    height: 1.5rem;
                                }
                            }

                            &.theme-editor-cell {
                                &.theme-editor-col-merge {
                                    overflow: visible;

                                    button {
                                        &.p-row-editor-init {
                                            margin-left: -1.25rem;
                                        }

                                        &.p-row-editor-save {
                                            margin-left: -1.25rem;
                                        }
                                    }
                                }

                                @include below-md() {
                                    width: 10px !important;
                                    min-width: 10px !important;
                                }

                                button {
                                    background: transparent;
                                    padding: .25rem;
                                    margin: 0;
                                    font-size: 1.125rem;
                                    line-height: 1;
                                    width: 28px;
                                    height: 28px;
                                    border-radius: 0;
                                    color: $secondary;
                                    background-repeat: no-repeat;
                                    background-position: center;

                                    @include below-md() {
                                        display: none;
                                    }

                                    svg {
                                        visibility: hidden;
                                    }

                                    &.p-row-editor-init {
                                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13'><path d='M1.5,11.791V14.5H4.208l7.987-7.987L9.487,3.8ZM14.289,4.418a.719.719,0,0,0,0-1.018L12.6,1.71a.719.719,0,0,0-1.018,0L10.259,3.031l2.708,2.708,1.321-1.321Z' transform='translate(-1.5 -1.499)' fill='%23afa46e'/></svg>");
                                    }

                                    &.p-row-editor-save {
                                        margin-right: .25rem;
                                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='18.282' height='14' viewBox='0 0 18.282 14'><path d='M28.62,13.845l-1.494-1.537a.321.321,0,0,0-.238-.1h0a.308.308,0,0,0-.238.1L16.292,22.742l-3.77-3.77a.328.328,0,0,0-.475,0l-1.511,1.511a.338.338,0,0,0,0,.484l4.754,4.754a1.5,1.5,0,0,0,.993.484,1.576,1.576,0,0,0,.985-.467h.008L28.628,14.329A.363.363,0,0,0,28.62,13.845Z' transform='translate(-10.434 -12.206)' fill='%23198754'/></svg>");
                                    }

                                    &.p-row-editor-cancel {
                                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='13.426' height='13.423' viewBox='0 0 13.426 13.423'><path d='M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z' transform='translate(-11.285 -11.289)' fill='%23dc3545'/></svg>");
                                    }
                                }
                            }

                            @include below-md() {
                                &.hideViewInMobile {
                                    .theme-view-btn {
                                        display: none;
                                    }
                                }

                                &.hideViewPopupInMobile {
                                    .theme-view-popup-btn {
                                        display: none;
                                    }
                                }

                                &.hideEditInMobile {
                                    .theme-edit-btn {
                                        display: none;
                                    }
                                }

                                &.hideDeleteInMobile {
                                    .theme-delete-btn {
                                        display: none;
                                    }
                                }

                                &.hideAddMappingInMobile {
                                    .theme-add-mapping-btn {
                                        display: none;
                                    }
                                }

                                &.hideAddMappingWidthChange {
                                    width: 120px !important;
                                    min-width: 120px !important;
                                }

                                &.hideCheckInMobile,
                                &.hideReorderInMobile,
                                &.theme-hide-cell-in-mobile {
                                    display: none;
                                }

                                &.hideActionMoreInMobile {
                                    .theme-action-more-btn {
                                        display: none;
                                    }
                                }

                                &.hideViewstatusInMobile {
                                    .theme-view-status-btn {
                                        pointer-events: none;
                                    }
                                }

                                &.hideEditPopupInMobile {
                                    .theme-edit-popup-btn {
                                        display: none;
                                    }
                                }

                                &.hideResetPopupInMobile {
                                    .theme-reset-popup-btn {
                                        display: none;
                                    }
                                }

                                &.hideCopyPopupInMobile {
                                    .theme-copy-popup-btn {
                                        display: none;
                                    }
                                }

                                &.hideSignPopupInMobile {
                                    .theme-sign-popup-btn {
                                        display: none;
                                    }
                                }

                                &.hideMarkAsCompletedInMobile {
                                    .theme-mark-as-completed-btn {
                                        display: none;
                                    }
                                }

                                &.hideDownloadAsCsvInMobile {
                                    .theme-download-as-csv-btn {
                                        display: none;
                                    }
                                }
                            }

                            .theme-table-fields-sm {
                                .p-inputtext {
                                    padding-top: 0.375rem;
                                    padding-bottom: 0.375rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .p-paginator {
        justify-content: flex-end;
        font-size: $theme-size-15;

        @include below-sm() {
            justify-content: center;
        }

        &::before {
            content: "Rows per page:";
            color: $body-color;
            order: -1;
            margin-right: .25rem;
        }

        .current-page-report {
            @include below-sm() {
                padding: 0 0 .25rem;
                width: 100%;
            }
        }

        .p-paginator-current {
            height: 2rem;
            cursor: auto;
        }

        .p-paginator-first,
        .p-paginator-prev,
        .p-paginator-next,
        .p-paginator-last {
            background-repeat: no-repeat;
            background-position: center;

            &:not(.p-disabled) {
                &:not(.p-highlight) {
                    &:hover {
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
            }

            svg {
                visibility: hidden;
            }
        }

        .p-paginator-first {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='7.797' height='10.058' viewBox='0 0 7.797 10.058'><path d='M-14624.294,10586.029l.528-.532,4.5-4.5,1.062,1.057-3.969,3.972,3.969,3.969-1.062,1.061Zm-1.707,5v-10h1.5v10Z' transform='translate(14626.001 -10581.001)'/></svg>");

            &:not(.p-disabled) {
                &:not(.p-highlight) {
                    &:hover {
                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='7.797' height='10.058' viewBox='0 0 7.797 10.058'><path d='M-14624.294,10586.029l.528-.532,4.5-4.5,1.062,1.057-3.969,3.972,3.969,3.969-1.062,1.061Zm-1.707,5v-10h1.5v10Z' transform='translate(14626.001 -10581.001)'/></svg>");
                    }
                }
            }
        }

        .p-paginator-prev {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6.092' height='10.061' viewBox='0 0 6.092 10.061'><path d='M-17364.516-12413.077l-5.029-5.03.529-.53,4.5-4.5,1.063,1.061-3.971,3.97,3.971,3.97Z' transform='translate(17369.545 12423.138)'/></svg>");

            &:not(.p-disabled) {
                &:not(.p-highlight) {
                    &:hover {
                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6.092' height='10.061' viewBox='0 0 6.092 10.061'><path d='M-17364.516-12413.077l-5.029-5.03.529-.53,4.5-4.5,1.063,1.061-3.971,3.97,3.971,3.97Z' transform='translate(17369.545 12423.138)'/></svg>");
                    }
                }
            }
        }

        .p-paginator-next {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6.092' height='10.061' viewBox='0 0 6.092 10.061'><path d='M-17367.951-12413.077l-1.062-1.061,3.971-3.97-3.971-3.97,1.063-1.061,5.029,5.03-.529.53Z' transform='translate(17369.014 12423.138)'/></svg>");

            &:not(.p-disabled) {
                &:not(.p-highlight) {
                    &:hover {
                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6.092' height='10.061' viewBox='0 0 6.092 10.061'><path d='M-17367.951-12413.077l-1.062-1.061,3.971-3.97-3.971-3.97,1.063-1.061,5.029,5.03-.529.53Z' transform='translate(17369.014 12423.138)'/></svg>");
                    }
                }
            }
        }

        .p-paginator-last {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='7.797' height='10.059' viewBox='0 0 7.797 10.059'><path d='M1.707,5.029l.528.532,4.5,4.5L7.8,9,3.828,5.029,7.8,1.06,6.735,0ZM0,.028v10H1.5v-10Z' transform='translate(7.797 10.058) rotate(180)'/></svg>");

            &:not(.p-disabled) {
                &:not(.p-highlight) {
                    &:hover {
                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='7.797' height='10.059' viewBox='0 0 7.797 10.059'><path d='M1.707,5.029l.528.532,4.5,4.5L7.8,9,3.828,5.029,7.8,1.06,6.735,0ZM0,.028v10H1.5v-10Z' transform='translate(7.797 10.058) rotate(180)'/></svg>");
                    }
                }
            }
        }

        .p-paginator-pages {
            font-size: $theme-size-15;
        }

        .p-dropdown {
            margin: 0;
            align-items: center;
            border: 0;
            padding-left: .5rem;

            .p-dropdown-label {
                padding: 0;
                font-size: $theme-size-15;
            }

            .p-dropdown-trigger {
                position: relative;
                width: 2rem;

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    border-top: 5px solid $body-color;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    transform: translate(-50%, -50%);
                }

                svg {
                    display: none;
                }
            }

        }
    }
}

.theme-actions-menu {
    &.p-menu {
        width: 122px;
        font-size: 1rem;
        font-weight: 600;

        &.p-menu-overlay {
            border-color: $secondary-border-subtle;
            padding: 0;

            .p-menu-list {
                li {
                    .p-menuitem-link {
                        padding: 11px;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid $secondary-border-subtle;
                    }
                }
            }
        }
    }
}

.theme-data-table-sm {
    .dataGridMain {
        .p-datatable {
            .p-datatable-wrapper {
                .p-datatable-table {
                    .p-datatable-thead {
                        tr {
                            th {
                                height: 40px;
                            }
                        }
                    }

                    .p-datatable-tbody {
                        >tr {

                            >td {
                                height: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}