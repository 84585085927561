.p-accordion {
    .p-accordion-tab {
        box-shadow: none;
        margin-bottom: 1rem;
        border-radius: $borderRadius;
        position: relative;
        transition: margin-bottom 225ms;
        border: 1px solid #E6E6E6;

        &:first-child {
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
        }

        &:last-child {
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }

        .p-accordion-toggle-icon {
            order: 1;
            margin-left: auto;
            transition: transform $transitionDuration;
            width: 1rem;
            height: 1rem;
            min-width: 1rem;
            min-height: 1rem;
        }

        &:not(.p-accordion-tab-active) {
            .p-accordion-header-link {
                &:focus {
                    background: $accordionHeaderHoverBg;
                }
            }

            .p-accordion-toggle-icon {
                transform: rotate(-270deg);
            }
        }

        &.p-accordion-tab-active {
            margin-bottom: 1rem;
            
            .p-accordion-toggle-icon {
                transform: rotate(-180deg);
            }
        }

        .p-accordion-header {
            &.p-disabled {
                opacity: 1;
    
                .p-accordion-header-link > * {
                    opacity: $disabledOpacity;
                }
            }
        }
    }
}