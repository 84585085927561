@import '../css/variables';
@import '~bootstrap/scss/bootstrap';

:root {
    // Override css variables of react-toastify
    --toastify-color-info: #{$info};
    --toastify-color-success: #{$success};
    --toastify-color-warning: #{$warning};
    --toastify-color-error: #{$danger};
    --toastify-font-family: #{$font-family-base};
    --toastify-color-progress-light: #{$primary};
    --toastify-color-progress-dark: #{$primary};
}

.tooltip {
    position: fixed !important;

    .tooltip-arrow {
        display: none;
    }
}

// Generate CSS for specific classes
@include custom-class('width', 44);
@include custom-class('height', 44);
@include generate-color-classes($custom-colors);

// Custom Scrollbar
@include above-xl() {
    * {
        scrollbar-width: thin;
        scrollbar-color: var(--bs-dark);

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--bs-dark);
            border-radius: 20px;

            &:hover {
                background-color: var(--bs-primary);
            }
        }
    }
}

//Custom Scrollbar 2
.theme-custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $theme-scroll-thumb-bg;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 20px;
        background-color: $theme-scroll-bg;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $theme-scroll-thumb-bg;
        border-radius: 20px;

        &:hover {
            background-color: var(--bs-dark);
        }
    }
}

//Custom Classes
.mw-1 {
    min-width: 1px;
}

.fs-8 {
    font-size: $theme-size-8 !important;
}

.fs-9 {
    font-size: $theme-size-9 !important;
}

.fs-10 {
    font-size: $theme-size-10 !important;
}

.fs-11 {
    font-size: $theme-size-11 !important;
}

.fs-12 {
    font-size: $theme-size-12 !important;
}

.fs-13 {
    font-size: $theme-size-13 !important;
}

.fs-14 {
    font-size: $theme-size-14 !important;
}

.fs-15 {
    font-size: $theme-size-15 !important;
}

.min-w-20 {
    min-width: 20px;
}

.min-w-30 {
    min-width: 30px;
}

.min-w-39 {
    min-width: 39px;
}

.min-w-50 {
    min-width: 50px;
}

.min-w-60 {
    min-width: 60px;
}

.min-w-64 {
    min-width: 64px;
}

.min-w-70 {
    min-width: 70px;
}

.min-w-80 {
    min-width: 80px;
}

.min-w-90 {
    min-width: 90px;
}

.min-w-100 {
    min-width: 100px;
}

.min-w-160 {
    min-width: 160px;
}

.h-10 {
    height: 10px;
}

.w-20 {
    width: 20px;
}

.h-20 {
    height: 20px;
}

.w-28 {
    width: 28px;
}

.h-28 {
    height: 28px;
}

.w-32 {
    width: 32px;
}

.h-32 {
    height: 32px;
}

.w-39 {
    width: 39px;
}

.h-39 {
    height: 39px;
}

.w-42 {
    width: 42px;
}

.h-42 {
    height: 42px;
}

.w-60 {
    width: 60px;
}

.h-60 {
    height: 60px;
}

.w-64 {
    width: 64px;
}

.h-64 {
    height: 64px;
}

.h-100px {
    height: 100px;
}

.max-w-150 {
    max-width: 150px;
}

.w-100px {
    width: 100px;
}

.w-120 {
    width: 120px;
}

.w-140 {
    width: 140px;
}

.w-144 {
    width: 144px;
}

.w-150 {
    width: 150px;
}

.w-180 {
    width: 180px;
}

.w-190 {
    width: 190px;
}

.w-200 {
    width: 200px;
}

.w-220 {
    width: 220px;
}

.w-236 {
    width: 236px;
}

.w-250 {
    width: 250px;
}

.w-285 {
    width: 285px;
}

.w-342 {
    width: 342px;
}

.max-w-200 {
    max-width: 200px;
}

.max-w-250 {
    max-width: 250px;
}

.max-w-350 {
    max-width: 350px;
}

.max-w-450 {
    max-width: 450px;
}

.max-h-460 {
    max-height: 460px;

    @include below-md() {
        max-height: 250px;
    }
}

.min-h-50 {
    min-height: 50px;
}

.min-h-100 {
    min-height: 100px;
}

.pageContainer {
    overflow-y: auto !important;

    .pageContent {
        overflow: visible !important;
    }
}

.theme-card-header {
    h5 {
        padding-left: 70px;

        .theme-icon-box {
            top: -36px;

            .theme-icon-box-inner {
                width: 35px;
                height: 24px;

                svg {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
}

.theme-from {
    .theme-common-form {
        height: 100%;
    }
}

.theme-filter-col-2 {
    @include below-md() {
        flex: 1 1 auto;

        .w-150,
        .w-180,
        .w-190,
        .w-200,
        .w-220,
        .w-236,
        .w-250,
        .w-285,
        .w-342 {
            width: 100%;
        }

        .theme-common-form {
            flex: 1 1 auto;
        }
    }

    .btn {
        @include below-sm() {
            flex: 1 1 auto;
        }
    }

    &.theme-filter-col-large {
        @include below-1600() {
            .theme-common-form {
                flex: 1 1 auto;
            }

            .w-150,
            .w-180,
            .w-200,
            .w-220,
            .w-236,
            .w-250,
            .w-285,
            .w-342 {
                width: 100%;
            }
        }
    }

    &.theme-filter-col-medium {
        @include below-1300() {
            .theme-common-form {
                flex: 1 1 auto;
            }

            .w-180,
            .w-200,
            .w-220,
            .w-236,
            .w-250,
            .w-285,
            .w-342 {
                width: 100%;
            }
        }
    }
}

.theme-from-footer,
.mx-n20 {
    margin-left: -20px;
    margin-right: -20px;
}

textarea {
    resize: none;

    &.p-disabled {
        pointer-events: all;
    }
}

.theme-advanced-filter-cover {

    .w-236,
    .w-180 {
        @include below-md() {
            width: calc(50% - 2rem);
        }

        @include below-sm() {
            width: 100%;
        }
    }

    @include below-1800() {
        &.flex-1800-nowrap {
            flex-wrap: wrap !important;
        }

        &.justify-content-1800-end {
            justify-content: flex-start !important;
        }
    }
}

.theme-advanced-filter-xl {

    .w-236,
    .w-180 {
        @include below-xl() {
            width: calc(50% - 2rem);
        }

        @include below-sm() {
            width: 100%;
        }
    }
}

.theme-group-fields {
    @include below-md() {
        .w-144 {
            width: 100px;
        }
    }
}

.btn-outline-primary {
    --bs-btn-hover-color: #{$primary};
    --bs-btn-hover-bg: #{rgba($primary, .04)};
    --bs-btn-hover-border-color: #{$primary};
    --bs-btn-active-color: #{$primary};
    --bs-btn-active-bg: #{rgba($primary, .16)};
}

.btn-outline-secondary {
    --bs-btn-hover-color: #{$secondary};
    --bs-btn-hover-bg: #{rgba($secondary, .04)};
    --bs-btn-hover-border-color: #{$secondary};
    --bs-btn-active-color: #{$secondary};
    --bs-btn-active-bg: #{rgba($secondary, .16)};
}

.pb-2px {
    padding-bottom: 2px;
}

.p-20 {
    padding: 20px;
}

.px-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.theme-outer-label {
    @include above-lg() {
        position: absolute;
        margin-top: 2rem;
        margin-left: 0;
    }

    @include above-xl() {
        margin-left: -10px;
    }
}

.theme-border-dashed {
    z-index: 1;
    border-radius: 6px;
    padding: 1px;

    &::before {
        border: 2px dashed #707070;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: 6px;
    }
}

.theme-modal {
    &.theme-modal-1100 {
        @include above-xl() {
            .modal-xl {
                --bs-modal-width: 1100px;
            }
        }
    }

    &.theme-modal-810 {
        @include above-lg() {

            .modal-lg,
            .modal-xl {
                --bs-modal-width: 810px;
            }
        }
    }

    &.theme-modal-500 {
        @include above-sm() {
            .modal-dialog {
                --bs-modal-width: 500px;
            }
        }
    }

    @include below-md() {
        --bs-modal-width: 540px;
    }

    .modal-dialog-scrollable {
        .modal-content {
            .theme-common-form {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                max-height: 100%;
            }
        }
    }
}

.theme-dropdown-arrow-hide {
    &.dropdown-toggle {
        &::after {
            display: none;
        }
    }
}

.theme-flip-x {
    transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
}

.theme-bs-table {

    th,
    td {
        height: 50px;
        vertical-align: middle;
    }
}

.text-truncate-line-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
}

.theme-lh-lg {
    line-height: 1.714;
}

.theme-yellow-light-40 {
    background-color: rgba($theme-yellow-light, .40);
}

.theme-light-red-bg {
    background-color: $theme-red-light-color;
}

.theme-odd-even-list {
    li {
        &:nth-child(odd) {
            background-color: $table-striped-bg;
        }
    }
}

.list-style-position-inside {
    list-style-position: inside;
}

@include below-md() {
    .theme-disabled-in-mobile {
        pointer-events: none;

        .btn {
            opacity: .65;
        }

        .p-inputtext,
        .p-dropdown,
        .p-multiselect {
            pointer-events: none;
            background-color: $bg-body-secondary;
            opacity: 1;
            color: rgba($black, .5);

            .p-inputtext {
                color: rgba($black, .5);
            }
        }
    }
}

.gap-88 {
    @include above-xl() {
        gap: 88px !important;
    }
}

.gx-xl-90 {
    @include above-xl() {
        --bs-gutter-x: 90px !important;
    }
}

.theme-input-start-gap {
    .theme-input-start-gap {
        padding-left: 36px;
    }
}

//Prime Custom CSS
.p-toast {
    &.p-toast-top-right {
        max-width: 20rem;

        @include below-sm() {
            top: 10px;
            right: 10px;
        }

        .p-toast-message {
            .p-toast-message-content {
                .p-toast-detail {
                    margin: 0;
                }
            }
        }
    }
}

.p-inputtext,
.p-dropdown,
.p-multiselect {
    &.p-disabled {
        background-color: $bg-body-secondary;
        opacity: 1;
        color: rgba($black, .5);

        .p-inputtext {
            color: rgba($black, .5);
        }
    }
}

.p-inputtext {
    &.p-inputtext-sm {
        padding-left: 10px;
        padding-right: 10px;
        font-size: $theme-size-14;
    }

    &.theme-inputtext-lg {
        padding-top: .75rem;
        padding-bottom: .75rem;
        font-size: 1rem;
    }
}

.p-inputwrapper {
    &.p-inputtext-sm {
        .p-inputtext {
            padding-left: 10px;
            padding-right: 10px;
            font-size: $theme-size-14;
        }
    }

    &.theme-inputtext-lg {
        .p-inputtext {
            padding-top: .75rem;
            padding-bottom: .75rem;
        }
    }

    &.p-input-icon-right {
        &.p-password {
            .p-inputtext {
                padding-right: 3rem;
            }

            >i {
                cursor: pointer;
                right: .75rem;
            }
        }
    }
}

.p-input-icon-left,
.p-input-icon-right {

    >i,
    >svg,
    >.p-input-prefix {
        margin-top: 0;
        transform: translateY(-50%);
    }
}

.p-button {
    font-size: 0.875rem;

    &.theme-button-lg {
        font-size: 1rem;
        padding-top: 0.8125rem;
        padding-bottom: 0.8125rem;
        font-weight: normal;
    }
}

.p-checkbox {
    .p-checkbox-box {
        &.p-highlight {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' fill='%23FFF' width='24'><path d='M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z'/></svg>");
            background-position: center;
            background-size: 17px;

            svg {
                visibility: hidden;
            }
        }
    }

    &:not(.p-checkbox-disabled) {
        .p-checkbox-box {
            &.p-highlight {
                &:hover {
                    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' fill='%23FFF' width='24'><path d='M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z'/></svg>");
                    background-position: center;
                    background-size: 17px;
                }
            }
        }
    }
}

.p-dropdown-trigger,
.p-multiselect-trigger {
    .p-icon {
        width: .875rem;
        height: .875rem;
    }
}

.p-dropdown-panel {
    margin-top: 2px;
    max-width: 200px;

    .p-dropdown-header {
        .p-dropdown-filter {
            font-size: $theme-size-12;
            border-color: $bg-body-secondary;
            background-color: $bg-body-secondary;
            border-radius: 6px;
        }

        .p-dropdown-filter-icon {
            display: none;
        }
    }

    .p-dropdown-items {
        padding-bottom: .5rem;
        padding-top: .5rem;

        .p-dropdown-item {
            white-space: normal;
            word-break: break-word;
        }
    }

}

.p-multiselect-panel {
    margin-top: 2px;
    max-width: 200px;

    .p-multiselect-header {
        .p-multiselect-filter-container {
            margin-right: 0;

            .p-inputtext {
                font-size: $theme-size-12;
                border-color: $bg-body-secondary;
                background-color: $bg-body-secondary;
                border-radius: 6px;
            }

            .p-multiselect-filter-icon {
                display: none;
            }
        }

        .p-multiselect-close {
            display: none;
        }
    }

    .p-multiselect-items {
        padding-bottom: .5rem;
        padding-top: .5rem;

        .p-multiselect-item {
            white-space: normal;
            word-break: break-word;

            &.p-highlight {
                background: transparent !important;
            }
        }
    }
}

.p-autocomplete-panel {
    max-width: 200px;

    .p-autocomplete-items {
        .p-autocomplete-item {
            white-space: normal;
            word-break: break-word;
        }
    }
}

.p-datepicker {
    font-size: 1rem;

    .p-link,
    table {
        font-size: 1rem;
    }

    table {
        th {
            text-align: center;
            font-size: 1rem;
        }
    }

    .p-datepicker-header {
        .p-datepicker-title {
            line-height: 1rem;
        }
    }
}


.p-sortable-column-badge {
    display: none !important;
}

.w-130 {
    width: 130px;
}

.pr-35 {
    padding-right: 35px;

    @include below-xl() {
        padding-right: 10px;
    }

}

.border-bottom-dashed {
    border-bottom: 1px dashed $secondary-border-subtle;
}

.border-light-bottom-dashed {
    border-bottom: 1px dashed $border-color;
}

.accordian-fullview-cover {
    .p-accordion-tab {
        border: none;
    }

    .p-accordion-content {
        padding-bottom: 0;
        border-top: 1px solid $border-color;

        >.row {
            &:last-child {
                .border-light-bottom-dashed {
                    border-bottom: none;
                }
            }
        }
    }

}

.border-r-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.border-r-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

//Theme Order Accordion
.theme-order-accordion {
    .p-orderlist-controls {
        padding-left: 0;
        padding-top: 5px;
        justify-content: start;
    }

    &.p-orderlist {
        .p-orderlist-droppoint {
            height: .25rem;
        }

        .p-orderlist-list-container {
            min-width: 1px;
        }

        .p-orderlist-list {
            border: 0;
            background: transparent;
            border-radius: 0;
            min-height: inherit;
            max-height: inherit;

            .p-orderlist-item {
                padding: 0;
                margin-bottom: .75rem;
                min-height: 62px;

                &.p-highlight {
                    background: transparent;

                    .p-accordion-tab {
                        border-color: $primary;
                    }
                }

                .p-accordion {
                    .p-accordion-tab {
                        margin-bottom: 0;
                    }

                    .p-accordion-tab.p-accordion-tab-active {
                        margin-bottom: 0;
                    }

                    .p-accordion-header {
                        .p-accordion-header-link {
                            height: 62px;
                        }
                    }
                }
            }
        }
    }
}

.p-editor-container {
    .ql-snow {
        .ql-picker {
            font-weight: 600;
        }
    }
}

.opacity-1 {
    opacity: .1;
}

a,
.btn-link {
    text-decoration: none;
}

ol,
ul,
dl {
    margin: 0;
    padding: 0;
}

.theme-loader-z-index {
    z-index: 1002 !important;
}

.p-autocomplete-multiple {
    .p-inputtext {
        width: 100%;
    }

}

.client-case-members-select>div {
    width: 100%;
}

.text-input-clone-print,
.check-image,
.radio-image-hide {
    display: none;
}

/* Print styles */
@media print {
    @page {
        margin: 0.5rem;
    }

    .text-input-clone-print {
        display: block;
        border: 1px solid #999999;
        border-radius: 4px;
        padding: 0.5rem 0.625rem;
    }

    input {
        &+.text-input-clone-print {
            min-height: 39px;
            position: relative;
        }
    }

    textarea {
        &+.text-input-clone-print {
            min-height: 66px;
        }
    }

    .check-image {
        display: block;
        position: absolute;
        top: -4px;
        left: 0;
    }

    .check-image svg {
        width: 16px;
        height: auto;
    }

    .check-image svg path {
        fill: $primary;
    }

    .radio-cover {
        position: relative;
    }

    .radio-cover .p-radiobutton-checked {
        opacity: 0;
    }

    .hide-input-print {
        display: none;
    }

    body {
        &:has(> .is-print-area, > .is-print-modal) {
            > :not(.is-print-area, .is-print-modal) {
                display: none;
            }
        }

        .theme-top-header,
        .sidebarMenu {
            display: none !important;
        }

        .pageContainer,
        .overflow-hidden,
        .overflow-auto {
            overflow: visible !important;
        }
    }

    .is-print-area {
        background-color: white;

        .theme-print-hide {
            display: none !important;
        }

        .theme-print-ps-0 {
            padding-left: 0 !important;
        }

        .p-accordion {
            .p-accordion-header {
                .btn-link {
                    display: none !important;
                }

                .p-accordion-header-link {
                    .p-accordion-toggle-icon {
                        display: none !important;
                    }
                }
            }

            .p-accordion-tab {
                page-break-inside: avoid;
            }
        }
    }

    .is-print-modal {
        position: static;
        background-color: white;

        .modal-dialog {
            width: 100%;
            height: 100%;
            max-width: 100%;
            min-height: 100%;
            margin: 0;

            .modal-content {
                border-radius: 0;
                margin: 0;
                height: 100%;

                .btn {
                    display: none;
                }

                .btn-close {
                    display: none;
                }
            }
        }
    }

    .Toastify {
        display: none;
    }

}

.p-accordion-tab {
    &:has(.custom-accordion-active) {
        border-color: var(--bs-danger);
    }
}

.new-theme-updates {
    --bs-primary-bg-subtle:#{map-get($custom-colors, primary-subtle)};
    --bs-success:#{map-get($custom-colors, success)};
    --bs-success-rgb: 25, 140, 114;
    --bs-success-bg-subtle:#{map-get($custom-colors, success-subtle)};
    --bs-danger:#{map-get($custom-colors, danger)};
    --bs-danger-bg-subtle:#{map-get($custom-colors, danger-subtle)};
    --bs-danger-rgb: 224, 79, 39;
    --bs-warning:#{map-get($custom-colors, warning)};
    --bs-warning-bg-subtle:#{map-get($custom-colors, warning-subtle)};
    --bs-warning-rgb: 220, 133, 2;
    --bs-link-color: #{map-get($custom-colors, link)};
    --bs-link-color-rgb: 41, 138, 255;
    --bs-danger-text-emphasis: #{map-get($custom-colors, danger)};

    .dataGridMain {
        .badge {
            --bs-badge-font-weight: 400;
            --bs-badge-padding-y: 0.05em;
        }

        .p-datatable {
            .p-datatable-wrapper {
                .p-datatable-table {
                    .p-datatable-thead {
                        tr {
                            th {
                                color: var(--bs-primary);
                                border-width: 0 0 1px 0;
                                border-color: map-get($custom-colors, border);
                            }
                        }
                    }
                }
            }

            .p-datatable-tbody {
                >tr {
                    color: var(--bs-primary);
                }
            }
        }

        border: 1px solid map-get($custom-colors, border);
    }

    .p-datatable.p-datatable-striped {
        .p-datatable-tbody {
            >tr.p-row-odd {
                background: var(--bs-white);
            }
        }
    }
}