.p-input-filled {
    .p-password.p-invalid {
        > .p-inputtext {
            @include invalid-filled-input();
        }
    }
}

.p-password.p-invalid {
    > .p-inputtext {
        &:enabled:focus {
            box-shadow: inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor;
        }
    }
}