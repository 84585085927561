$colors: (
    "blue": #2196F3,
    "green": #689F38,
    "yellow": #FBC02D,
    "cyan": #00BCD4,
    "pink": #E91E63,
    "indigo": #4E5FBB,
    "teal": #009688,
    "orange": #FF9800,
    "bluegray": #607D8B,
    "purple": #9C27B0,
    "red": #F44336,
    "primary": $primaryColor
) !default;

$emphasis-high:rgba(0,0,0,1) !default;
$emphasis-medium:rgba(0,0,0,.60) !default;
$emphasis-low:#999999 !default;
$emphasis-lower:rgba(0,0,0,.12) !default;
$overlayColor:#000000 !default;

//global
$fontFamily:'Source Sans Pro', sans-serif !default;
$fontSize:0.875rem !default;
$fontWeight:normal !default;
$textColor:$emphasis-high !default;
$textSecondaryColor:$emphasis-medium !default;
$borderRadius:4px !default;
$transitionDuration:.2s !default;
$formElementTransition:background-color $transitionDuration, border-color $transitionDuration, color $transitionDuration, box-shadow $transitionDuration, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1) !default;
$actionIconTransition:background-color $transitionDuration, color $transitionDuration, box-shadow $transitionDuration !default;
$listItemTransition:none !default;
$primeIconFontSize:1rem !default;
$divider:1px solid rgba(0,0,0,.12) !default;
$inlineSpacing:.5rem !default;
$disabledOpacity:.38 !default;
$maskBg:rgba(0, 0, 0, 0.32) !default;
$loadingIconFontSize:2rem !default;
$errorColor:#B00020 !default;

//selected state
$highlightBg:rgba($primaryColor, .20) !default;
$highlightTextColor:$emphasis-high !default;

//scale
$scaleSM:0.45 !default;
$scaleLG:1.25 !default;

//focus
$focusOutlineColor:transparent !default;
$focusOutline:0 none !default;
$focusOutlineOffset:0 !default;
$focusShadow:none !default;

//action icons
$actionIconWidth:2.5rem !default;
$actionIconHeight:2.5rem !default;
$actionIconBg:transparent !default;
$actionIconBorder:0 none !default;
$actionIconColor:$textSecondaryColor !default;
$actionIconHoverBg:rgba(0,0,0,.04) !default;
$actionIconHoverBorderColor:transparent !default;
$actionIconHoverColor:$textSecondaryColor !default;
$actionIconBorderRadius:50% !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding:1rem 1rem !default;
$inputTextFontSize:0.875rem !default;
$inputBg:#ffffff !default;
$inputTextColor:$emphasis-high !default;
$inputIconColor:$emphasis-medium !default;
$inputBorder:1px solid $emphasis-low !default;
$inputHoverBorderColor:$emphasis-high !default;
$inputFocusBorderColor:$primaryColor !default;
$inputErrorBorderColor:$errorColor !default;
$inputPlaceholderTextColor:$emphasis-medium !default;
$inputFilledBg:#f5f5f5 !default;
$inputFilledHoverBg:#ececec !default;
$inputFilledFocusBg:#dcdcdc !default;

//input groups
$inputGroupBg:$inputBg !default;
$inputGroupTextColor:$emphasis-medium !default;
$inputGroupAddOnMinWidth:2rem !default;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg:#ffffff !default;
$inputListTextColor:$textColor !default;
$inputListBorder:1px solid #e5e5e5 !default;
$inputListPadding:0 !default;
$inputListItemPadding:1rem 1rem !default;
$inputListItemBg:transparent !default;
$inputListItemTextColor:$textColor !default;
$inputListItemHoverBg:rgba(0,0,0,.04) !default;
$inputListItemTextHoverColor:$textColor !default;
$inputListItemBorder:0 none !default;
$inputListItemBorderRadius:0 !default;
$inputListItemMargin:0 !default;
$inputListItemFocusShadow:none !default;
$inputListHeaderPadding:1rem !default;
$inputListHeaderMargin:0 !default;
$inputListHeaderBg:#ffffff !default;
$inputListHeaderTextColor:$textColor !default;
$inputListHeaderBorder:0 !default;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg:$inputListBg !default;
$inputOverlayHeaderBg:$inputListHeaderBg !default;
$inputOverlayBorder:1px solid #999999 !default;                  
$inputOverlayShadow: none !default;

//password
$passwordMeterBg:rgba($primaryColor, .32) !default;
$passwordWeakBg:#D32F2F !default;
$passwordMediumBg:#FBC02D !default;
$passwordStrongBg:#689F38 !default;

//button
$buttonPadding:0.714rem 1rem !default;
$buttonIconOnlyWidth:2rem !default;
$buttonIconOnlyPadding:0.714rem !default;
$buttonBg:$primaryColor !default;
$buttonTextColor:$primaryTextColor !default;
$buttonBorder:0 none !default;
$buttonHoverBg:rgba($primaryColor, .92) !default;
$buttonTextHoverColor:$primaryTextColor !default;
$buttonHoverBorderColor:transparent !default;
$buttonActiveBg:rgba($primaryColor, .68) !default;
$buttonTextActiveColor:$primaryTextColor !default;
$buttonActiveBorderColor:transparent !default;
$raisedButtonShadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !default;
$roundedButtonBorderRadius:2rem !default;

$textButtonHoverBgOpacity:.04 !default;
$textButtonActiveBgOpacity:.16 !default;
$outlinedButtonBorder:0 none !default;
$plainButtonTextColor:$textSecondaryColor !default;
$plainButtonHoverBgColor:rgba(0,0,0,.04) !default;
$plainButtonActiveBgColor:rgba(0,0,0,.16) !default;

$secondaryButtonBg:$accentColor !default;
$secondaryButtonTextColor:$accentTextColor !default;
$secondaryButtonBorder:0 none !default;
$secondaryButtonHoverBg:rgba($accentColor, .92) !default;
$secondaryButtonTextHoverColor:$accentTextColor !default;
$secondaryButtonHoverBorderColor:transparent !default;
$secondaryButtonActiveBg:rgba($accentColor, .68) !default;
$secondaryButtonTextActiveColor:$accentTextColor !default;
$secondaryButtonActiveBorderColor:transparent !default;
$secondaryButtonFocusShadow:none !default;

$infoButtonBg:#2196F3 !default;
$infoButtonTextColor:#ffffff !default;
$infoButtonBorder:0 none !default;
$infoButtonHoverBg:rgba(#2196F3, .92) !default;
$infoButtonTextHoverColor:#ffffff !default;
$infoButtonHoverBorderColor:transparent !default;
$infoButtonActiveBg:rgba(#2196F3, .68) !default;
$infoButtonTextActiveColor:#ffffff !default;
$infoButtonActiveBorderColor:transparent !default;
$infoButtonFocusShadow:none !default;

$successButtonBg:#689F38 !default;
$successButtonTextColor:#ffffff !default;
$successButtonBorder:0 none !default;
$successButtonHoverBg:rgba(#689F38, .92) !default;
$successButtonTextHoverColor:#ffffff !default;
$successButtonHoverBorderColor:transparent !default;
$successButtonActiveBg:rgba(#689F38, .68) !default;
$successButtonTextActiveColor:#ffffff !default;
$successButtonActiveBorderColor:transparent !default;
$successButtonFocusShadow:none !default;

$warningButtonBg:#FBC02D !default;
$warningButtonTextColor:#212529 !default;
$warningButtonBorder:0 none !default;
$warningButtonHoverBg:rgba(#FBC02D, .92) !default;
$warningButtonTextHoverColor:#212529 !default;
$warningButtonHoverBorderColor:transparent !default;
$warningButtonActiveBg:rgba(#FBC02D, .68) !default;
$warningButtonTextActiveColor:#212529 !default;
$warningButtonActiveBorderColor:transparent !default;
$warningButtonFocusShadow:none !default;

$helpButtonBg:#9C27B0 !default;
$helpButtonTextColor:#ffffff !default;
$helpButtonBorder:0 none !default;
$helpButtonHoverBg:rgba(#9C27B0, .92) !default;
$helpButtonTextHoverColor:#ffffff !default;
$helpButtonHoverBorderColor:transparent !default;
$helpButtonActiveBg:rgba(#9C27B0, .68) !default;
$helpButtonTextActiveColor:#ffffff !default;
$helpButtonActiveBorderColor:transparent !default;
$helpButtonFocusShadow:none !default;

$dangerButtonBg:#D32F2F !default;
$dangerButtonTextColor:#ffffff !default;
$dangerButtonBorder:0 none !default;
$dangerButtonHoverBg:rgba(#D32F2F, .92) !default;
$dangerButtonTextHoverColor:#ffffff !default;
$dangerButtonHoverBorderColor:transparent !default;
$dangerButtonActiveBg:rgba(#D32F2F, .68) !default;
$dangerButtonTextActiveColor:#ffffff !default;
$dangerButtonActiveBorderColor:transparent !default;
$dangerButtonFocusShadow:none !default;

$linkButtonColor:$primaryColor !default;
$linkButtonHoverColor:$primaryColor !default;
$linkButtonTextHoverDecoration:underline !default;
$linkButtonFocusShadow:none !default;

//checkbox
$checkboxWidth:16px !default;
$checkboxHeight:16px !default;
$checkboxBorder:2px solid #999999 !default;
$checkboxIconFontSize:12px !default;
$checkboxActiveBorderColor:$primaryColor !default;
$checkboxActiveBg:$primaryColor !default;
$checkboxIconActiveColor:$primaryTextColor !default;
$checkboxActiveHoverBg:$primaryColor !default;
$checkboxIconActiveHoverColor:$primaryTextColor !default;
$checkboxActiveHoverBorderColor:$primaryColor !default;

//radiobutton
$radiobuttonWidth:1rem !default;
$radiobuttonHeight:1rem !default;
$radiobuttonBorder:2px solid #999999 !default;
$radiobuttonIconSize:.5rem !default;
$radiobuttonActiveBorderColor:$primaryColor !default;
$radiobuttonActiveBg:$primaryTextColor !default;
$radiobuttonIconActiveColor:$primaryColor !default;
$radiobuttonActiveHoverBg:$primaryTextColor !default;
$radiobuttonIconActiveHoverColor:$primaryColor !default;
$radiobuttonActiveHoverBorderColor:$primaryColor !default;

//colorpicker
$colorPickerPreviewWidth:2rem !default;
$colorPickerPreviewHeight:2rem !default;
$colorPickerBg:#323232 !default;
$colorPickerBorder:1px solid #191919 !default;
$colorPickerHandleColor:#ffffff !default;

//togglebutton
$toggleButtonBg:#ffffff !default;
$toggleButtonBorder:1px solid rgba(0,0,0,.12) !default;
$toggleButtonTextColor:$textColor !default;
$toggleButtonIconColor:$textSecondaryColor !default;
$toggleButtonHoverBg:#f6f6f6 !default;
$toggleButtonHoverBorderColor:rgba(0,0,0,.12) !default;
$toggleButtonTextHoverColor:$textColor !default;
$toggleButtonIconHoverColor:$textSecondaryColor !default;
$toggleButtonActiveBg:#e0e0e1 !default;
$toggleButtonActiveBorderColor:#e0e0e1 !default;
$toggleButtonTextActiveColor:$textColor !default;
$toggleButtonIconActiveColor:$textSecondaryColor !default;
$toggleButtonActiveHoverBg:#d9d8d9 !default;
$toggleButtonActiveHoverBorderColor:#d9d8d9 !default;
$toggleButtonTextActiveHoverColor:$textColor !default;
$toggleButtonIconActiveHoverColor:$textSecondaryColor !default;

//inplace
$inplacePadding:$inputPadding !default;
$inplaceHoverBg:rgba(0,0,0,.04) !default;
$inplaceTextHoverColor:$textColor !default;

//rating
$ratingIconFontSize:1.143rem !default;
$ratingCancelIconColor:#B00020 !default;
$ratingCancelIconHoverColor:#B00020 !default;
$ratingStarIconOffColor:$primaryColor !default;
$ratingStarIconOnColor:$primaryColor !default;
$ratingStarIconHoverColor:$primaryColor !default;

//slider
$sliderBg:#c1c1c1 !default;
$sliderBorder:0 none !default;
$sliderHorizontalHeight:2px !default;
$sliderVerticalWidth:2px !default;
$sliderHandleWidth:20px !default;
$sliderHandleHeight:20px !default;
$sliderHandleBg:$primaryColor !default;
$sliderHandleBorder:0 none !default;
$sliderHandleBorderRadius:50% !default;
$sliderHandleHoverBorderColor:0 none !default;
$sliderHandleHoverBg:$primaryColor !default;
$sliderRangeBg:$primaryColor !default;

//calendar
$calendarTableMargin:.5rem 0 !default;
$calendarPadding:.5rem !default;
$calendarBg:#ffffff !default;
$calendarInlineBg:$calendarBg !default;
$calendarTextColor:$textColor !default;
$calendarBorder:$inputListBorder !default;
$calendarOverlayBorder:$inputOverlayBorder !default;

$calendarHeaderPadding:.25rem !default;
$calendarHeaderBg:#ffffff !default;
$calendarInlineHeaderBg:$calendarBg !default;
$calendarHeaderBorder:$divider !default;
$calendarHeaderTextColor:$textColor !default;
$calendarHeaderFontWeight:500 !default;
$calendarHeaderCellPadding:.25rem !default;
$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;

$calendarCellDatePadding:.5rem !default;
$calendarCellDateWidth:2.5rem !default;
$calendarCellDateHeight:2.5rem !default;
$calendarCellDateBorderRadius:50% !default;
$calendarCellDateBorder:1px solid transparent !default;
$calendarCellDateHoverBg:rgba(0,0,0,.04) !default;
$calendarCellDateTodayBg:#ffffff !default;
$calendarCellDateTodayBorderColor: rgba(0,0,0,12) !default;
$calendarCellDateTodayTextColor:$textColor !default;

$calendarButtonBarPadding:1rem 0 !default;
$calendarTimePickerPadding:.5rem !default;
$calendarTimePickerElementPadding:0 .5rem !default;
$calendarTimePickerTimeFontSize:1.25rem !default;

$calendarBreakpoint:769px !default;
$calendarCellDatePaddingSM:0 !default;

//input switch
$inputSwitchWidth:2.4375rem !default;
$inputSwitchHeight:1.6875rem !default;
$inputSwitchBorderRadius:30px !default;
$inputSwitchHandleWidth:1.0625rem !default;
$inputSwitchHandleHeight:1.0625rem !default;
$inputSwitchHandleBorderRadius:50% !default;
$inputSwitchSliderPadding:.25rem !default;
$inputSwitchSliderOffBg:#BEBEBE !default;
$inputSwitchHandleOffBg:#FFFFFF !default;
$inputSwitchSliderOffHoverBg:scale-color(#BEBEBE, $lightness: -10%) !default;
$inputSwitchSliderOnBg:$successColor !default;
$inputSwitchSliderOnHoverBg:$successColor !default;
$inputSwitchHandleOnBg:#FFFFFF !default;

//panel
$panelHeaderBorderColor: #e0e0e0 !default;
$panelHeaderBorder:1px solid #e0e0e0 !default;
$panelHeaderBg:#ffffff !default;
$panelHeaderTextColor:$textColor !default;
$panelHeaderFontWeight:500 !default;
$panelHeaderPadding:1rem !default;
$panelToggleableHeaderPadding:.5rem 1rem !default;

$panelHeaderHoverBg:rgba(0,0,0,.04) !default;
$panelHeaderHoverBorderColor:#e0e0e0 !default;
$panelHeaderTextHoverColor:$textColor !default;

$panelContentBorderColor: #e0e0e0 !default;
$panelContentBorder:1px solid #e0e0e0 !default;
$panelContentBg:#ffffff !default;
$panelContentEvenRowBg:rgba(0,0,0,.02) !default;
$panelContentTextColor:$textColor !default;
$panelContentPadding:1rem !default;

$panelFooterBorder:1px solid #e0e0e0 !default;
$panelFooterBg:#ffffff !default;
$panelFooterTextColor:$textColor !default;
$panelFooterPadding:1rem 1rem !default;

//accordion
$accordionSpacing:1rem !default;
$accordionHeaderBorder:0 none !default;
$accordionHeaderBg:#ffffff !default;
$accordionHeaderTextColor:$textColor !default;
$accordionHeaderFontWeight:400 !default;
$accordionHeaderPadding:1.5rem !default;

$accordionHeaderHoverBg:#ffffff !default;
$accordionHeaderHoverBorderColor:transparent !default;
$accordionHeaderTextHoverColor:$textColor !default;

$accordionHeaderActiveBg:#ffffff !default;
$accordionHeaderActiveBorderColor:transparent !default;
$accordionHeaderTextActiveColor:$textColor !default;

$accordionHeaderActiveHoverBg:#ffffff !default;
$accordionHeaderActiveHoverBorderColor:transparent !default;
$accordionHeaderTextActiveHoverColor:$textColor !default;

$accordionContentBorder:0 none !default;
$accordionContentBg:#ffffff !default;
$accordionContentTextColor:$textColor !default;
$accordionContentPadding:1rem 1.5rem !default;

//tabview
$tabviewNavBorder:solid #EBEBEB !default;
$tabviewNavBorderWidth:0 0 1px 0 !default;
$tabviewNavBg:#ffffff !default;

$tabviewHeaderSpacing:0 !default;
$tabviewHeaderBorder:solid #EBEBEB !default;
$tabviewHeaderBorderWidth:0 0 1px 0 !default;
$tabviewHeaderBorderColor:transparent transparent #EBEBEB transparent !default;
$tabviewHeaderBg:#ffffff !default;
$tabviewHeaderTextColor:$textColor !default;
$tabviewHeaderFontWeight:400 !default;
$tabviewHeaderPadding:1rem 1.5rem !default;
$tabviewHeaderMargin:0 0 -1px 0 !default;

$tabviewHeaderHoverBg:#ffffff !default;
$tabviewHeaderHoverBorderColor:#EBEBEB !default;
$tabviewHeaderTextHoverColor:$textColor !default;

$tabviewHeaderActiveBg:#ffffff !default;
$tabviewHeaderActiveBorderColor:#EBEBEB !default;
$tabviewHeaderTextActiveColor:$textColor !default;

$tabviewContentBorder:0 none !default;
$tabviewContentBg:#ffffff !default;
$tabviewContentTextColor:$textColor !default;
$tabviewContentPadding:$panelContentPadding !default;

//upload
$fileUploadProgressBarHeight:4px !default;
$fileUploadContentPadding:2rem 1rem !default;

//scrollpanel
$scrollPanelTrackBorder:0 none !default;
$scrollPanelTrackBg:#EBEBEB !default;

//card
$cardBodyPadding:1rem !default;
$cardTitleFontSize:1.5rem !default;
$cardTitleFontWeight:700 !default;
$cardSubTitleFontWeight:400 !default;
$cardSubTitleColor:$textSecondaryColor !default;
$cardContentPadding:1rem 0 !default;
$cardFooterPadding:1rem 0 0 0 !default;
$cardShadow:0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12) !default;

//editor
$editorToolbarBg:$panelHeaderBg !default;
$editorToolbarBorder:$panelHeaderBorder !default;
$editorToolbarPadding:$panelHeaderPadding !default;
$editorToolbarIconColor:$textSecondaryColor !default;
$editorToolbarIconHoverColor:$textColor !default;
$editorIconActiveColor:$primaryColor !default;
$editorContentBorder:$panelContentBorder !default;
$editorContentBg:$panelContentBg !default;

//paginator
$paginatorBg:#ffffff !default;
$paginatorTextColor:$textColor !default;
$paginatorBorder:solid #e4e4e4 !default;
$paginatorBorderWidth:0 !default;
$paginatorPadding:.5rem 1rem !default;
$paginatorElementWidth:$buttonIconOnlyWidth !default;
$paginatorElementHeight:$buttonIconOnlyWidth !default;
$paginatorElementBg:transparent !default;
$paginatorElementBorder:0 none !default;
$paginatorElementIconColor:$emphasis-high !default;
$paginatorElementHoverBg:rgba(0,0,0,.1) !default;
$paginatorElementHoverBorderColor:transparent !default;
$paginatorElementIconHoverColor:$emphasis-high !default;
$paginatorElementBorderRadius:50% !default;
$paginatorElementMargin:.143rem !default;
$paginatorElementPadding:0 !default;

//table
$tableHeaderBorder:1px solid #e4e4e4 !default;
$tableHeaderBorderWidth:0 0 1px 0 !default;
$tableHeaderBg:#ffffff !default;
$tableHeaderTextColor:$textColor !default;
$tableHeaderFontWeight:500 !default;
$tableHeaderPadding:1rem 1rem !default;

$tableHeaderCellPadding:1rem 1rem !default;
$tableHeaderCellBg:#F5F5F5 !default;
$tableHeaderCellTextColor:$textColor !default;
$tableHeaderCellFontWeight:600 !default;
$tableHeaderCellBorder:1px solid #e4e4e4 !default;
$tableHeaderCellBorderWidth:0 0 0 0 !default;
$tableHeaderCellHoverBg:rgba(0,0,0,.05) !default;
$tableHeaderCellTextHoverColor:$textColor !default;
$tableHeaderCellIconColor:$textSecondaryColor !default;
$tableHeaderCellIconHoverColor:$textSecondaryColor !default;
$tableHeaderCellHighlightBg:#F5F5F5 !default;
$tableHeaderCellHighlightTextColor:$textColor !default;
$tableHeaderCellHighlightHoverBg:rgba(0,0,0,.04) !default;
$tableHeaderCellHighlightTextHoverColor:$textColor !default;
$tableSortableColumnBadgeSize:1.143rem !default;

$tableBodyRowBg:#ffffff !default;
$tableBodyRowTextColor:$textColor !default;
$tableBodyRowEvenBg:#F5F5F5 !default;
$tableBodyRowHoverBg:#F5F5F5 !default;
$tableBodyRowTextHoverColor:$textColor !default;
$tableBodyCellBorder:1px solid #e4e4e4 !default;
$tableBodyCellBorderWidth:0 0 0 0 !default;
$tableBodyCellPadding:1rem 1rem !default;

$tableFooterCellPadding:1rem 1rem !default;
$tableFooterCellBg:#ffffff !default;
$tableFooterCellTextColor:$textColor !default;
$tableFooterCellFontWeight:500 !default;
$tableFooterCellBorder:1px solid #e4e4e4 !default;
$tableFooterCellBorderWidth:0 0 0 0 !default;
$tableResizerHelperBg:$primaryColor !default;
$tableDragHelperBg: rgba($primaryColor, .16) !default;

$tableFooterBorder:1px solid #e4e4e4 !default;
$tableFooterBorderWidth:0 0 0 0 !default;
$tableFooterBg:#ffffff !default;
$tableFooterTextColor:$textColor !default;
$tableFooterFontWeight:500 !default;
$tableFooterPadding:1rem 1rem !default;

$tableCellContentAlignment:left !default;
$tableTopPaginatorBorderWidth:0 0 1px 0 !default;
$tableBottomPaginatorBorderWidth:0 0 1px 0 !default;

$tableScaleSM:0.5 !default;
$tableScaleLG:1.25 !default;

//dataview
$dataViewContentPadding:1rem 0 !default;
$dataViewContentBorder:0 none !default;
$dataViewListItemBorder:solid rgba(0,0,0,.12) !default;
$dataViewListItemBorderWidth:0 0 1px 0 !default;

//datascroller
$dataScrollerContentPadding:1rem 0 !default;
$dataScrollerContentBorder:0 none !default;
$dataScrollerListItemBorder:solid rgba(0,0,0,.12) !default;
$dataScrollerListItemBorderWidth:0 0 1px 0 !default;

//schedule
$fullCalendarEventBg:$highlightBg !default;
$fullCalendarEventBorderColor:$highlightBg !default;
$fullCalendarEventBorder:1px solid $highlightBg !default;
$fullCalendarEventTextColor:$highlightTextColor !default;

//tree
$treeNodePadding:.25rem !default;
$treeNodeContentPadding:.5rem !default;
$treeNodeChildrenPadding:0 0 0 1rem !default;
$treeNodeIconColor:$textSecondaryColor !default;

//timeline
$timelineVerticalEventContentPadding:0 1rem !default;
$timelineHorizontalEventContentPadding:1rem 0 !default;
$timelineEventMarkerWidth:1rem !default;
$timelineEventMarkerHeight:1rem !default;
$timelineEventMarkerBorderRadius:50% !default;
$timelineEventMarkerBorder:0 none !default;
$timelineEventMarkerBackground:#bdbdbd !default;
$timelineEventConnectorSize:2px !default;
$timelineEventColor:#bdbdbd !default;

//org chart
$organizationChartConnectorColor:rgba(0,0,0,.12) !default;

//message
$messageMargin:1rem 0 !default;
$messagePadding: 1.25rem 1.5rem !default;
$messageBorderWidth:0 0 0 0 !default;
$messageIconFontSize:1.5rem !default;
$messageTextFontSize:1rem !default;
$messageTextFontWeight:500 !default;

//inline message
$inlineMessagePadding:$inputPadding !default;
$inlineMessageMargin:0 !default;
$inlineMessageIconFontSize:1rem !default;
$inlineMessageTextFontSize:1rem !default;
$inlineMessageBorderWidth:1px !default;

//toast
$toastIconFontSize:1.25rem !default;
$toastMessageTextMargin:0 0 0 1rem !default;
$toastMargin:0 0 1rem 0 !default;
$toastPadding:1rem !default;
$toastBorderWidth:0 0 0 0 !default;
$toastShadow:0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12) !default;
$toastOpacity:.9 !default;
$toastTitleFontWeight:700 !default;
$toastDetailMargin:$inlineSpacing 0 0 0 !default;

//severities
$infoMessageBg:#B3E5FC !default;
$infoMessageBorder:solid transparent !default;
$infoMessageTextColor:#052865 !default;
$infoMessageIconColor:#052865 !default;
$successMessageBg:#D1E7DD !default;
$successMessageBorder:solid transparent !default;
$successMessageTextColor:$successColor !default;
$successMessageIconColor:$successColor !default;
$warningMessageBg:#FFECB3 !default;
$warningMessageBorder:solid transparent !default;
$warningMessageTextColor:#7f6003 !default;
$warningMessageIconColor:#7f6003 !default;
$errorMessageBg:#FFCDD2 !default;
$errorMessageBorder:solid transparent !default;
$errorMessageTextColor:#B71C1C !default;
$errorMessageIconColor:#B71C1C !default;

//overlays
$overlayContentBorder:0 none !default;
$overlayContentBg:$panelContentBg !default;
$overlayContainerShadow:0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12) !default;

//dialog
$dialogHeaderBg:#ffffff !default;
$dialogHeaderBorder:0 none !default;
$dialogHeaderTextColor:$textColor !default;
$dialogHeaderFontWeight:500 !default;
$dialogHeaderFontSize:1.25rem !default;
$dialogHeaderPadding:1.5rem !default;
$dialogContentPadding: 0 1.5rem 1.5rem 1.5rem !default;
$dialogFooterBorder:0 none !default;
$dialogFooterPadding:1rem 1.5rem !default;

//confirmpopup
$confirmPopupContentPadding:1.5rem !default;
$confirmPopupFooterPadding:0 1.5rem 1rem 1.5rem !default;

//tooltip
$tooltipBg:rgba(97,97,97,.9) !default;
$tooltipTextColor:#ffffff !default;
$tooltipPadding:.5rem !default;

//steps
$stepsItemBg:transparent !default;
$stepsItemBorder:1px solid transparent !default;
$stepsItemTextColor:$textColor !default;
$stepsItemNumberWidth:2rem !default;
$stepsItemNumberHeight:2rem !default;
$stepsItemNumberFontSize:1.143rem !default;
$stepsItemNumberColor:$textColor !default;
$stepsItemNumberBorderRadius:50% !default;
$stepsItemActiveFontWeight:500 !default;

//progressbar
$progressBarHeight:4px !default;
$progressBarBorder:0 none !default;
$progressBarBg:rgba($primaryColor, .32) !default;
$progressBarValueBg:$primaryColor !default;
$progressBarValueTextColor:$primaryTextColor !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth:inherit !default;
$menuBg:#ffffff !default;
$menuBorder:1px solid #CFCFCF !default;
$menuTextColor:$textColor !default;
$menuitemPadding:1rem 1rem !default;
$menuitemBorderRadius:.25rem !default;
$menuitemTextColor:$textColor !default;
$menuitemIconColor:$textSecondaryColor !default;
$menuitemTextHoverColor:$textColor !default;
$menuitemIconHoverColor:$textSecondaryColor !default;
$menuitemHoverBg:#EBEBEB !default;
$menuitemTextActiveColor:$textColor !default;
$menuitemIconActiveColor:$textSecondaryColor !default;
$menuitemActiveBg:#EBEBEB !default;
$menuitemSubmenuIconFontSize:.875rem !default;
$submenuHeaderMargin:0 !default;
$submenuHeaderPadding:1rem !default;
$submenuHeaderBg:#ffffff !default;
$submenuHeaderTextColor:$textSecondaryColor !default;
$submenuHeaderBorderRadius:0 !default;
$submenuHeaderFontWeight:400 !default;
$overlayMenuBg:$menuBg !default;
$overlayMenuBorder:1px solid #CFCFCF !default;
$overlayMenuShadow:none !default;
$verticalMenuPadding:.5rem .5rem !default;
$menuSeparatorMargin:.5rem .5rem !default;

$breadcrumbPadding:1rem !default;
$breadcrumbBg:#ffffff !default;
$breadcrumbBorder:1px solid #e5e5e5 !default;
$breadcrumbItemTextColor:$textColor !default;
$breadcrumbItemIconColor:$textSecondaryColor !default;
$breadcrumbLastItemTextColor:$textColor !default;
$breadcrumbLastItemIconColor:$textSecondaryColor !default;
$breadcrumbSeparatorColor:$textSecondaryColor !default;

$horizontalMenuPadding:1rem !default;
$horizontalMenuBg:transparent !default;
$horizontalMenuBorder:1px solid #e5e5e5 !default;
$horizontalMenuTextColor:$textColor !default;
$horizontalMenuRootMenuitemPadding:1rem !default;
$horizontalMenuRootMenuitemBorderRadius:$borderRadius !default;
$horizontalMenuRootMenuitemTextColor:$textColor !default;
$horizontalMenuRootMenuitemIconColor:$textSecondaryColor !default;
$horizontalMenuRootMenuitemTextHoverColor:$textColor !default;
$horizontalMenuRootMenuitemIconHoverColor:$textSecondaryColor !default;
$horizontalMenuRootMenuitemHoverBg:rgba(0,0,0,.04) !default;
$horizontalMenuRootMenuitemTextActiveColor:$textColor !default;
$horizontalMenuRootMenuitemIconActiveColor:$textSecondaryColor !default;
$horizontalMenuRootMenuitemActiveBg:rgba(0,0,0,.04) !default;

//badge and tag
$badgeBg:$primaryColor !default;
$badgeTextColor:$primaryTextColor !default;
$badgeMinWidth:1.5rem !default;
$badgeHeight:1.5rem !default;
$badgeFontWeight:700 !default;
$badgeFontSize:.75rem !default;

$tagPadding:.25rem .4rem !default;

//carousel
$carouselIndicatorsPadding:1rem !default;
$carouselIndicatorBg:#dcdcdc !default;
$carouselIndicatorHoverBg:#ececec !default;
$carouselIndicatorBorderRadius:0 !default;
$carouselIndicatorWidth:2rem !default;
$carouselIndicatorHeight:.5rem !default;

//galleria
$galleriaMaskBg:rgba(0,0,0,0.9) !default;
$galleriaCloseIconMargin:.5rem !default;
$galleriaCloseIconFontSize:2rem !default;
$galleriaCloseIconBg:transparent !default;
$galleriaCloseIconColor:rgba(255,255,255,.87) !default;
$galleriaCloseIconHoverBg:rgba(255,255,255,0.1) !default;
$galleriaCloseIconHoverColor:rgba(255,255,255,.87) !default;
$galleriaCloseIconWidth:4rem !default;
$galleriaCloseIconHeight:4rem !default;
$galleriaCloseIconBorderRadius:50% !default;

$galleriaItemNavigatorBg:transparent !default;
$galleriaItemNavigatorColor:#f6f6f6 !default;
$galleriaItemNavigatorMargin:0 .5rem !default;
$galleriaItemNavigatorFontSize:2rem !default;
$galleriaItemNavigatorHoverBg:rgba(255,255,255,0.1) !default;
$galleriaItemNavigatorHoverColor:rgba(255,255,255,.87) !default;
$galleriaItemNavigatorWidth:4rem !default;
$galleriaItemNavigatorHeight:4rem !default;
$galleriaItemNavigatorBorderRadius:50% !default;

$galleriaCaptionBg:rgba(0,0,0,.5) !default;
$galleriaCaptionTextColor:rgba(255,255,255,.87) !default;
$galleriaCaptionPadding:1rem !default;

$galleriaIndicatorsPadding:1rem !default;
$galleriaIndicatorBg:#dcdcdc !default;
$galleriaIndicatorHoverBg:#ececec !default;
$galleriaIndicatorBorderRadius:50% !default;
$galleriaIndicatorWidth:1.25rem !default;
$galleriaIndicatorHeight:1.25rem !default;
$galleriaIndicatorsBgOnItem:rgba(0,0,0,.5) !default;
$galleriaIndicatorBgOnItem:rgba(255,255,255,.4) !default;
$galleriaIndicatorHoverBgOnItem:rgba(255,255,255,.6) !default;

$galleriaThumbnailContainerBg:rgba(0,0,0,.9) !default;
$galleriaThumbnailContainerPadding:1rem .25rem !default;
$galleriaThumbnailNavigatorBg:transparent !default;
$galleriaThumbnailNavigatorColor:rgba(255,255,255,.87) !default;
$galleriaThumbnailNavigatorHoverBg:rgba(255,255,255,0.1) !default;
$galleriaThumbnailNavigatorHoverColor:rgba(255,255,255,.87) !default;
$galleriaThumbnailNavigatorBorderRadius:50% !default;
$galleriaThumbnailNavigatorWidth:2rem !default;
$galleriaThumbnailNavigatorHeight:2rem !default;

//divider
$dividerHorizontalMargin:1.25rem 0 !default;
$dividerHorizontalPadding:0 1.25rem !default;
$dividerVerticalMargin:0 1.25rem !default;
$dividerVerticalPadding:1.25rem 0 !default;
$dividerSize:1px !default;
$dividerColor:rgba(0,0,0,.12) !default;

//avatar
$avatarBg:rgba(0,0,0,.12) !default;
$avatarTextColor:$textColor !default;

//chip
$chipBg:rgba(0,0,0,.12) !default;
$chipTextColor:$textColor !default;
$chipBorderRadius: 16px !default;

//scrollTop
$scrollTopBg:$accentColor !default;
$scrollTopHoverBg:rgba($accentColor, .92) !default;
$scrollTopWidth:3rem !default;
$scrollTopHeight:3rem !default;
$scrollTopBorderRadius:50% !default;
$scrollTopFontSize:1.5rem !default;
$scrollTopTextColor:$accentTextColor !default;

//skeleton
$skeletonBg:rgba(0,0,0,.08) !default;
$skeletonAnimationBg:rgba(255,255,255,0.4) !default;

//splitter
$splitterGutterBg:rgba(0,0,0,.04) !default;
$splitterGutterHandleBg:rgba(0,0,0,.12) !default;

//speeddial
$speedDialButtonWidth: 4rem !default;
$speedDialButtonHeight: 4rem !default;
$speedDialButtonIconFontSize: 2rem !default;
$speedDialActionWidth: 3rem !default;
$speedDialActionHeight: 3rem !default;
$speedDialActionBg: $accentColor !default;
$speedDialActionHoverBg: rgba($accentColor, .92) !default;
$speedDialActionTextColor: #fff !default;
$speedDialActionTextHoverColor: #fff !default;

//dock
$dockActionWidth: 4rem !default;
$dockActionHeight: 4rem !default;
$dockItemPadding: .5rem !default;
$dockCurrentItemMargin: 1.5rem !default;
$dockFirstItemsMargin: 1.3rem !default;
$dockSecondItemsMargin: 0.9rem !default;
$dockBg: rgba(255,255,255,.1) !default;
$dockBorder: 1px solid rgba(255,255,255,0.2) !default;
$dockPadding: .5rem .5rem !default;
$dockBorderRadius: .5rem !default;

//image
$imageMaskBg:rgba(0,0,0,0.9) !default;
$imagePreviewToolbarPadding:1rem !default;
$imagePreviewIndicatorColor:#f8f9fa !default;
$imagePreviewIndicatorBg:rgba(0,0,0,0.5) !default;
$imagePreviewActionIconBg:transparent !default;
$imagePreviewActionIconColor:#f8f9fa !default;
$imagePreviewActionIconHoverBg:rgba(255,255,255,0.1) !default;
$imagePreviewActionIconHoverColor:#f8f9fa !default;
$imagePreviewActionIconWidth:3rem !default;
$imagePreviewActionIconHeight:3rem !default;
$imagePreviewActionIconFontSize:1.5rem !default;
$imagePreviewActionIconBorderRadius:50% !default;

:root {
    --surface-a:#ffffff;
    --surface-b:#fafafa;
    --surface-c:rgba(0,0,0,.04);
    --surface-d:rgba(0,0,0,.12);
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#{$textColor};
    --text-color-secondary:#{$textSecondaryColor};
    --primary-color:#{$primaryColor};
    --primary-color-text:#{$primaryTextColor};
    --font-family:#{$fontFamily};
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --gray-50: #FAFAFA;
    --gray-100: #F5F5F5;
    --gray-200: #EEEEEE;
    --gray-300: #E0E0E0;
    --gray-400: #BDBDBD;
    --gray-500: #9E9E9E;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#fafafa;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:rgba(0,0,0,.12);
    --surface-hover:rgba(0,0,0,.04);
    --focus-ring: #{$focusShadow};
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
}