//@import url('https://fonts.googleapis.com/css2?family=Calibri&family=Poppins&family=Raleway&family=Roboto+Slab&display=swap');
@import '../../../assets/css/variables';

$font-family-arial: 'Arial', sans-serif;
$font-family-calibri: 'Calibri', sans-serif;
$font-family-poppins: 'Poppins', sans-serif;
$font-family-raleway: 'Raleway', sans-serif;
$font-family-roboto-slab: 'Roboto Slab', sans-serif;

$font-families: (
  default: $font-family-base,
  arial: $font-family-arial,
  calibri: $font-family-calibri,
  poppins: $font-family-poppins,
  raleway: $font-family-raleway,
  roboto-slab: $font-family-roboto-slab,
);

.theme-editor-cover {
  @each $label, $family in $font-families {
    .ql-font {
      span[data-value="#{$label}"] {
        &::before {
          font-family: $family;
        }
      }
    }

    .ql-container {
      font-family: $font-family-base;

      .ql-font-#{$label} {
        font-family: $family;
      }
    }
  }
}