@import '../../assets/css/variables';
.alert-default{
    background-color: rgba($theme-yellow-light, .40);
    color: $secondary;
}
.dashboard-text-green{
    color: #00D640;
}
.dashboard-text-orange{
    color: #FFA200;
}
.dashboard-text-gray{
    color: $dark-border-subtle;
}
.dashboard-text-red{
    color: $theme-red-light-color;
}
.fs-20{
    font-size: 1.25rem;
}

.dashboard-card-box {
    @include above-1600() {
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
}